import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./FeApp.css";
import styles from "./FeApp.module.css";

import { HomePage } from "./pages/HomePage";
import { TeamPage } from "./pages/TeamPage";
import { ContactPage } from "./pages/ContactPage";
import { DonateFoodPage } from "./pages/DonateFoodPage";
import { DonateMoneyPage } from "./pages/DonateMoneyPage";
import { FaqPage } from "./pages/FaqPage";
import { AchievementsPage } from "./pages/AchievementsPage";
import { HelpRequestPage } from "./pages/HelpRequestPage";
import { CookiesProvider } from "react-cookie";

import { OrganisationPage } from "./pages/OrganisationPage";
import { Container } from "react-bootstrap";
import classNames from "classnames";
import { FeLanguage, FeResource } from "./fe-const";
import { LangSwitcherPage } from "./components/langSwitcher/LangSwitcher";
import ErrorPage from "./pages/ErrorPage";
import { VolunteersPage } from "./pages/VolunteersPage";

const FeApp = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <CookiesProvider>
          <Container as="main" className={classNames(styles.pawsMain)} fluid>
            {/*<RouterProvider router={router} />*/}
            <Routes>
              <Route path={FeResource.Root} element={<HomePage />} errorElement={<ErrorPage />} />
              <Route path={FeResource.RootAlt} element={<HomePage />} errorElement={<ErrorPage />} />

              <Route path={FeResource.Team} element={<TeamPage />} errorElement={<ErrorPage />} />
              <Route path={FeResource.Volunteers} element={<VolunteersPage />} errorElement={<ErrorPage />} />
              <Route path={FeResource.Faq} element={<FaqPage />} errorElement={<ErrorPage />} />
              <Route path={FeResource.Contact} element={<ContactPage />} errorElement={<ErrorPage />} />
              <Route path={FeResource.RequestHelp} element={<HelpRequestPage />} errorElement={<ErrorPage />} />
              <Route path={FeResource.DonateMoney} element={<DonateMoneyPage />} errorElement={<ErrorPage />} />
              <Route path={FeResource.DonateSupplies} element={<DonateFoodPage />} errorElement={<ErrorPage />} />
              <Route path={FeResource.Achievements} element={<AchievementsPage />} errorElement={<ErrorPage />} />
              <Route path={FeResource.Organisation} element={<OrganisationPage />} errorElement={<ErrorPage />} />
              <Route
                path={FeResource.Ukrainian}
                element={<LangSwitcherPage lang={FeLanguage.Ukr} />}
                errorElement={<ErrorPage />}
              />
              <Route
                path={FeResource.English}
                element={<LangSwitcherPage lang={FeLanguage.Eng} />}
                errorElement={<ErrorPage />}
              />
              <Route
                path={FeResource.Swedish}
                element={<LangSwitcherPage lang={FeLanguage.Swe} />}
                errorElement={<ErrorPage />}
              />
            </Routes>
          </Container>
        </CookiesProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default FeApp;
