import React from "react";
import { Header } from "../components/headerAndFooter/Header";
import { Footer } from "../components/headerAndFooter/Footer";
import { VolunteersPageBody } from "../components/VolunteersPageBody";

const VolunteersPage = () => (
  <>
    <Header currentPageId="volunteers" />
    <VolunteersPageBody />
    <Footer />
  </>
);

export { VolunteersPage };
