import React, { FC } from "react";
import { Link } from "react-router-dom";

import styles from "./Header.module.css";
import logo from "../../images/logo.png";
import { LangSwitcher, PawsLangStrings } from "../langSwitcher/LangSwitcher";

import { MenuBar, MenuItemDefinition } from "./Menu";
import { Col, Row } from "react-bootstrap";
import { FeLanguage, FeResource } from "../../fe-const";

const strings: PawsLangStrings = {
  [FeLanguage.Ukr]: {
    Home: "Головна",
    "Need help?": "Потребуєте допомоги?",
    "Donate Money": "Пожертва грошима",
    "Donate Supplies": "Пожертва речами",
    FAQs: "Питання",
    Team: "Наша команда",
    Achievements: "Наші досягнення",
    Contact: "Контакти",
    Organisation: "Наша організація",
    Volunteers: "Волонтери",
  },
  [FeLanguage.Swe]: {
    Home: "Hem",
    "Need help?": "", // not visible in English version
    "Donate Money": "Skänk pengar",
    "Donate Supplies": "Skänk saker/foder",
    FAQs: "", // not visible in English version
    Team: "Vårt team",
    Achievements: "Våra prestationer",
    Contact: "Kontakta oss",
    Organisation: "Vår organisation",
    Volunteers: "Volontärer",
  },
  [FeLanguage.Eng]: {
    "Need help?": "@", // not visible in English version
    FAQs: "@", // not visible in English version
  },
};

// const isDev = process.env.REACT_APP_DEV === "1";

const menuItems: MenuItemDefinition[] = [
  {
    to: FeResource.Root,
    highlightId: "home",
    text: "Home",
    nested: [
      { to: FeResource.RequestHelp, highlightId: "needHelp", text: "Need help?", lang: [FeLanguage.Ukr] },
      { to: FeResource.Faq, highlightId: "faq", text: "FAQs", lang: [FeLanguage.Ukr] },
    ],
  },
  {
    to: FeResource.DonateMoney,
    highlightId: "donateMoney",
    text: "Donate Money",
    nested: [{ to: FeResource.DonateSupplies, highlightId: "donateFood", text: "Donate Supplies" }],
  },
  {
    to: FeResource.Organisation,
    highlightId: "organisation",
    text: "Organisation",
    nested: [
      { to: FeResource.Team, highlightId: "team", text: "Team" },
      { to: FeResource.Achievements, highlightId: "achievements", text: "Achievements" },
    ],
  },
  {
    to: FeResource.Contact,
    highlightId: "contact",
    text: "Contact",
    nested: [{ to: FeResource.Volunteers, highlightId: "volunteers", text: "Volunteers" }],
  },
];

const Logo = () => {
  return (
    <Link to={FeResource.Root}>
      <img src={logo} className={styles.logo} alt="Paws of Peace" />
    </Link>
  );
};

interface HeaderProps {
  currentPageId: string;
}

export const Header: FC<HeaderProps> = ({ currentPageId }) => {
  return (
    <header className="d-flex flex-wrap justify-content-center py-2 mb-4 border-bottom">
      <Row className="w-100">
        <Col sm={1} lg={1} className={styles.logoContainer}>
          <Logo />
        </Col>

        <Col sm={9} lg={9}>
          <MenuBar strings={strings} currentPageId={currentPageId} items={menuItems} />
        </Col>

        <Col sm={1} lg={1}>
          <LangSwitcher />
        </Col>
      </Row>
    </header>
  );
};
