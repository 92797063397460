import React from "react";
import { PawsLangStrings, useTranslate } from "./langSwitcher/LangSwitcher";
import { FeLanguage, pawsVolunteerFormUrl } from "../fe-const";
import { Link } from "react-router-dom";

function MailTo(props: { email: string }) {
  return <a href={"mailto:" + props.email}>{props.email}</a>;
}

const strings: PawsLangStrings = {
  [FeLanguage.Ukr]: {
    Volunteers: "Волонтери",
    "Support us through becoming a member": "Підтримайте нас ставши учасником",
    "text.volunteer":
      "Волонтерська робота в Paws of Peace — це безоплатна допомога, зазвичай це пересування " +
      "та пакування коробок на адресі, де знаходиться Paws of Peace. Волонтери потрібні кожну неділю з 14 до 18. " +
      "Хочете приєднатися до команди волонтерів?",
    "text.volunteer.newLocation":
      "⚠️ УВАГА! Ми незабаром втрачаємо доступ до старого приміщення у місті та знайшли нове приміщення " +
      "(близько 15 хвилин потягом від центральної станції) і " +
      "з 1 березня 2025 року будемо працювати за новою " +
      "адресою, а також зможемо обрати інші, зручні для волонтерів дні та години роботи.",
    "Contact us": "Заповніть форму реєстрації волонтера",
  },
  [FeLanguage.Swe]: {
    Volunteers: "Volontärer",
    "Support us through becoming a member": "Stöd oss genom att bli medlem",
    "text.volunteer":
      "Volontärarbete på Paws of Peace är obetalt arbete, " +
      "vanligtvis innebär det att hjälpa till att flytta och packa donationer " +
      "i lådor. Vi behöver volontärer tillgängliga på söndagar från 14 till 18. " +
      "Vill du gå med i volontärteamet?",
    "text.volunteer.newLocation":
      "⚠️ OBS! Vi kommer snart att förlora tillgång till vårt gamla lokaler i staden " +
      "och har hittat nya lokaler (cirka 15 minuter med tåg från centralstationen) och " +
      "från och med 1 mars 2025 kommer vi att arbeta på en ny adress, samt kunna välja andra, " +
      "bekväma dagar och arbetstider för volontärer.",
    "Contact us": "Fyll i formuläret för volontärer",
  },
  [FeLanguage.Eng]: {
    "text.volunteer":
      "Volunteer work is unpaid work at the Paws of Peace location, usually involves " +
      "helping moving and packing the donations into boxes. We need volunteers available on Sundays " +
      "from 14 to 18. Want to join the volunteer team?",
    "text.volunteer.newLocation":
      "⚠️ ATTENTION! We are soon losing access to our old location in the city " +
      "and have found new premises (about 15 minutes by train from the central station) and " +
      "from 1 March 2025 we will be working at the new address, as well as being able to choose other, " +
      "convenient days and working hours for volunteers.",
    "Contact us": "Fill in the volunteer registration form",
  },
};

export const VolunteersPageBody = () => {
  const { translate: t, translateJsx } = useTranslate(strings);

  return (
    <>
      <h3>{t("Volunteers")}</h3>
      <p>{t("text.volunteer")}</p>
      <p>{t("text.volunteer.newLocation")}</p>

      <Link className="btn btn-primary" to={pawsVolunteerFormUrl}>
        {t("Contact us")}
      </Link>
    </>
  );
};
